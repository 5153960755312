
import { mapState } from 'vuex';
import Loader from '../Loader';
import PhotoImage from './PhotoImage';
import PhotoInfo from './PhotoInfo';
import PhotoZoom from './PhotoZoom';
import PhotoNotes from './PhotoNotes';
import PhotoIssues from './PhotoIssues';
import PhotoToolbar from './PhotoToolbar';

export default {
  name: 'Photo',
  components: {
    Loader,
    PhotoImage,
    PhotoToolbar,
    PhotoInfo,
    PhotoZoom,
    PhotoNotes,
    PhotoIssues,
  },
  computed: {
    ...mapState('inspections__new', [
      'currentPhoto',

      'getPhotoIssuesResult',

      'getInspectionIssuesResult',

      'getPhotoNotesResult',
    ]),
    ...mapState('inspections__new/photos', [
      'currentToolCode',
    ]),
  },
  methods: {
    getData() {
      if (!this.currentPhoto) {
        return;
      }
      if (!this.getPhotoIssuesResult || this.getPhotoIssuesResult.length === 0 || this.getPhotoIssuesResult[0].photo !== this.currentPhoto.id) {
        this.$store.dispatch('inspections__new/getPhotoIssues', { id: this.currentPhoto.id });
      }
      if (!this.getInspectionIssuesResult || this.getInspectionIssuesResult.length === 0 || this.getInspectionIssuesResult[0].inspection !== this.currentPhoto.inspection) {
        this.$store.dispatch('inspections__new/getInspectionIssues', { id: this.currentPhoto.inspection });
      }
      if (!this.getPhotoNotesResult || this.getPhotoNotesResult.length === 0 || this.getPhotoNotesResult[0].photo !== this.currentPhoto.id) {
        this.$store.dispatch('inspections__new/getPhotoNotes', { id: this.currentPhoto.id });
      }
    },
  },
  watch: {
    currentPhoto: {
      immidiate: true,
      handler() {
        this.getData();
      },
    },
  },
  beforeMount() {
    this.getData();
  },
};
