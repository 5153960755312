
import { mapState } from 'vuex';
import settings from '@/core/config/settings';
import PhotoZoom from './PhotoZoom';
import PhotoIssueData from './PhotoIssueData';

export default {
  name: 'PhotoIssuesCurrent',
  components: {
    PhotoZoom,
    PhotoIssueData,
  },
  data: () => ({
    type: '',
    comment: '',
    isConfirmationNeeded: false,
    isDetailsBlockVisible: false,
    positionX: null,
    positionY: null,
  }),
  computed: {
    ...mapState('inspections__new', [
      'currentPhoto',
      'currentInspection',

      'getPhotoIssuesResult',
      'getPhotoIssuesIsLoading',
      'getPhotoIssuesError',

      'addPhotoIssuesIsLoading',

      'removePhotoIssuesIsLoading',

      'updatePhotoIssuesIsLoading',
    ]),
    ...mapState('inspections__new/photos', [
      'currentIssue',
      'isDrawingActive',
      'drawingPolygonPoints',
      'drawingPolygonCenter',
      'cursorOffsetX',
      'cursorOffsetY',
    ]),
    isLoading() {
      return  this.getPhotoIssuesIsLoading || this.addPhotoIssuesIsLoading || this.removePhotoIssuesIsLoading || this.updatePhotoIssuesIsLoading;
    },
    isDetailsBlockAvailable() {
      return !this.isLoading
        && settings.bamOrganizationsIds.indexOf(this.currentInspection.organization) > -1
        && this.currentIssue.data;
    },
  },
  methods: {
    toggleDetails() {
      this.isDetailsBlockVisible = !this.isDetailsBlockVisible;
    },
    drawHandler() {
      this.$store.state.inspections__new.photos.drawingPolygonPoints = JSON.parse(this.getFormData().get('vertices')) || [];
      this.$store.commit('inspections__new/photos/recalculateDrawingPolygonCenter');
      this.$store.state.inspections__new.photos.isDrawingActive = true;
    },
    removePoint(index) {
      this.$store.dispatch('inspections__new/photos/removePolygonPoint', index);
    },
    clearPoints() {
      this.$store.state.inspections__new.photos.drawingPolygonPoints = [];
      this.$store.commit('inspections__new/photos/recalculateDrawingPolygonCenter');
    },
    getFormData() {
      const payload = new FormData();
      Object.keys(this.currentIssue).forEach(x => {
        if (x === 'vertices' || x === 'vertices_center') {
          if (typeof this.currentIssue[x] === 'string') {
            payload.append(x, this.currentIssue[x].split(' ').reduce((acc, curr) => { acc.push(curr.split(',')); return acc; }, []));
          } else {
            payload.append(x, JSON.stringify(this.currentIssue[x]) || 'null');
          }
        } else {
          payload.append(x, this.currentIssue[x] || 'null');
        }
      });
      return payload;
    },
    saveVertices() {
      const payload = this.getFormData();
      payload.append('vertices_center', JSON.stringify(this.drawingPolygonCenter));
      payload.append('vertices', JSON.stringify(this.drawingPolygonPoints));
      this.$store.dispatch('inspections__new/updatePhotoIssues', { id: this.currentIssue.id, payload });
    },
    onSubmit() {
      const payload = this.getFormData();
      payload.set('status', 'accepted');
      this.$store.dispatch('inspections__new/updatePhotoIssues', { id: this.currentIssue.id, payload });
    },
    rejectHandler() {
      const payload = this.getFormData();
      payload.set('status', 'rejected');
      this.$store.dispatch('inspections__new/updatePhotoIssues', { id: this.currentIssue.id, payload });
    },
    removeHandler() {
      this.isConfirmationNeeded = true;
    },
    cancel() {
      this.isConfirmationNeeded = false;
    },
    remove() {
      this.$store.dispatch('inspections__new/removePhotoIssues', { id: this.currentIssue.id });
      this.isConfirmationNeeded = false;
    },
    backHandler() {
      this.$store.state.inspections__new.photos.currentIssue = null;
    },
  },
  beforeUnmount() {
    this.$store.state.inspections__new.photos.currentIssue = null;
  },
  watch: {
    currentIssue: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.type = newVal.issue_type;
          this.comment = newVal.content === 'None' ? '' : newVal.content;
        } else {
          this.type = '';
          this.comment = '';
        }
      }
    },
    cursorOffsetX(newVal) {
      if (this.isDrawingActive) {
        this.positionX = Math.floor(newVal * this.currentPhoto.image_width);
      }
    },
    cursorOffsetY(newVal) {
      if (this.isDrawingActive) {
        this.positionY = Math.floor(newVal * this.currentPhoto.image_height);
      }
    },
  },
}
