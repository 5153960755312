
import { mapState } from 'vuex';
import Loader from '../Loader.vue';

export default {
  name: 'PhotoIssueData',
  components: {
    Loader,
  },
  props: {
    mode: {
      type: String,
      default: 'edit',
    },
    givenData: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    originalBamData: null,
    originalIssueData: null,
    isInitiated: false,
    element: '',
    buildingPart: '',
    material: '',
    customFieldsValues: {},
    hierarchyFieldsValues: {},
    customFieldsFormData: {
      4: {
        BusinessDataID: 4,
        Name: 'Ernst',
        options: [
          'Gering',
          'Serieus',
          'Ernstig',
        ],
      },
      5: {
        BusinessDataID: 5,
        Name: 'Omvang',
        options: [
          'Kleiner dan 2% (is heel weinig aanwezig en vaak ook heel slecht zichtbaar)',
          'Tussen 2% en 10% (is weinig aanwezig en matig zichtbaar)',
          'Tussen 10% en 30% (is goed zichtbaar)',
          'Tussen 30% en 70% (is duidelijk, overwegend in het merendeel aanwezig)',
          'Meer dan 70% (is overduidelijk, bijna geheel aanwezig)',
        ],
      },
      6: {
        BusinessDataID: 6,
        Name: 'Intensiteit',
        options: [
          'Beginstadium',
          'Gevorderd stadium',
          'Eindstadium',
        ],
      },
      7: {
        BusinessDataID: 7,
        Name: 'Oppervlakte schade (in m2)',
      },
      8: {
        BusinessDataID: 8,
        Name: 'Lengte schade (in m)',
      },
      9: {
        BusinessDataID: 9,
        Name: 'Schadevorm',
      },
      10: {
        BusinessDataID: 10,
        Name: 'Te treffen maatregel',
      },
      11: {
        BusinessDataID: 11,
        Name: 'Jaartal uitvoering',
      },
    },
  }),
  computed: {
    ...mapState('inspections__new', [
      'currentInspection',

      'getBamFieldsResult',
      'getBamFieldsIsLoading',
      'getBamFieldsError',
    ]),
    ...mapState('inspections__new/photos', [
      'currentIssue',
    ]),
    issue() {
      if (this.givenData) {
        return { data: this.givenData };
      }
      return { data: this.currentIssue?.data || undefined };
    },
    error() {
      if (!this.getBamFieldsResult) {
        return null;
      }
      const elements = this.getBamFieldsResult?.asset?.decomposition?.elements;
      if (!elements || elements.length === 0) {
        return 'Voer eerst decompositie op';
      }
    },
    fields() {
      const result = this.issue?.data?.issue?.reduce(
        (acc, curr) => {
          acc[curr.BusinessDataID] = {
            ...curr,
            id: curr.BusinessDataID,
            label: curr.Name,
            value: curr.Value,
            isCustomField: curr.BusinessDataID >= 4 && curr.BusinessDataID <= 11,
          };
          return acc;
        },
      {}) || {};
      Object.keys(this.customFieldsFormData).forEach(key => {
        result[key] = {
          ...this.customFieldsFormData[key],
          id: this.customFieldsFormData[key].BusinessDataID,
          label: this.customFieldsFormData[key].Name,
          isCustomField: true,
          value: result[key]?.value || result[key]?.Value || '',
          Value: result[key]?.value || result[key]?.Value || '',
        };
      });
      return result;
    },
    elementOptions() {
      if (!this.getBamFieldsResult) return [];
      return this.getBamFieldsResult.asset.decomposition.elements.map(x => ({
        ...x,
        id: '' + x.id,
        // title: isCombinedTitleNeeded ? `${x.type} (${x.name})` : (x.name || x.type),
        title: (x.name && x.type) ? `${x.type} (${x.name})` : (x.name || x.type),
        value: '' + x.id,
      }));
    },
    currentElementData() {
      if (!this.element) return null;
      return this.elementOptions.find(x => x.id == this.element);
    },
    buildingPartOptions() {
      if (!this.currentElementData) return [];
      return this.currentElementData.buildingParts?.map(x => ({
        ...x,
        id: '' + x.id,
        title: x.name || x.type,
        value: '' + x.id,
      })) || [];
    },
    currentBuildingPartData() {
      if (!this.buildingPart) return null;
      return this.buildingPartOptions.find(x => x.id == this.buildingPart);
    },
    materialOptions() {
      if (!this.currentBuildingPartData) return [];
      return this.currentBuildingPartData.materials.map(x => ({
        ...x,
        id: '' + x.id,
        title: x.name || x.type,
        value: '' + x.id,
      }));
    },
    customFields() {
      let result = Object.keys(this.fields).filter(x => this.fields[x].isCustomField);
      if (this.hierarchyFieldsValues[1274] !== 'Overig') {
        result = result.filter(x => x >= 4 && x <= 8);
      }
      result = result.reduce((acc, curr) => {
        acc[curr] = {
          ...this.fields[curr],
        };
        return acc;
      }, {});
      return result;
    },
    hierarchyFields() {
      if (!this.getBamFieldsResult?.hierarchy) return [];
      const baseFieldData = this.getBamFieldsResult.hierarchy;
      const result = [];
      let currentFieldId = baseFieldData?.id || null;
      const createField = ({ fieldData, isBaseField = false, prev = null }) => ({
        ...fieldData,
        label: fieldData.name,
        isBaseField,
        prev,
        options: fieldData.options?.map(x => ({
          ...x,
          next: x.next && Object.keys(x.next).length > 0? x.next : null,
          value: x.value,
          title: x.value,
        })),
      });
      result.push(createField({ fieldData: baseFieldData, isBaseField: true }));

      while (currentFieldId) {
        const selected = this.hierarchyFieldsValues[currentFieldId];
        const next = result[result.length - 1].options?.find(x => x.value === selected)?.next;
        if (next) {
          result.push(createField({ fieldData: next, prev: currentFieldId }));
          currentFieldId = next.id;
        } else {
          currentFieldId = null;
        }
      }
      return result;
    },
  },
  methods: {
    setCustomFieldValue(field, event) {
      this.customFieldsValues = {
        ...this.customFieldsValues,
        [field.id]: event.target.value,
      };
    },
    setHierarchyFieldValue(field, event) {
      const result = {
        ...this.hierarchyFieldsValues,
        [field.id]: event.target.value,
      };
      const nullifyNextFields = (fieldId) => {
        const nextField = this.hierarchyFields.find(x => x.prev === fieldId);
        if (nextField) {
          result[nextField.id] = undefined;
          nullifyNextFields(nextField.id);
        }
      };
      nullifyNextFields(field.id);
      this.hierarchyFieldsValues = result;
    },
    prepareData() {
      const result = [];
      if (this.element) {
        result.push({ BusinessDataID: this.fields[1]?.BusinessDataID || '1', Name: this.fields[1]?.Name || 'Element', Value: this.element || 'None' });
      }
      if (this.buildingPart) {
        result.push({ BusinessDataID: this.fields[2]?.BusinessDataID || '2', Name: this.fields[2]?.Name || 'BuildingPart', Value: this.buildingPart || 'None' });
      }
      if (this.material) {
        result.push({ BusinessDataID: this.fields[3]?.BusinessDataID || '3', Name: this.fields[3]?.Name || 'Material', Value: this.material || 'None' });
      }
      Object.keys(this.customFieldsValues).forEach(key => {
        result.push({ BusinessDataID: this.fields[key].BusinessDataID, Name: this.fields[key].Name, Value: this.customFieldsValues[key] || 'None' });
      });
      Object.keys(this.hierarchyFieldsValues).forEach(key => {
        const field = this.hierarchyFields.find(x => x.id == key);
        if (field) {
          result.push({ BusinessDataID: field.id, Name: field.name || field.type, Value: this.hierarchyFieldsValues[key] || 'None' });
        }
      });

      return {
        decisionServiceId: this.getBamFieldsResult.decisionServiceId,
        versionNumber: this.getBamFieldsResult.versionNumber,
        ...this.originalIssueData,
        issue: result,
      };
    },
    onSubmit() {
      if (this.mode === 'create') {
        this.$emit('save', this.prepareData());
      } else {
        const payload = new FormData();
        payload.append('data', JSON.stringify(this.prepareData()));
        this.$store.dispatch('inspections__new/updatePhotoIssues', { id: this.currentIssue.id, payload });
      }
    },
  },
  beforeMount() {
    this.originalIssueData = this.issue.data;
    this.$store.dispatch('inspections__new/getBamFields', {
      id: this.currentInspection.id,
      onSuccess: result => {
        this.element = this.fields[1]?.value || '';
        this.buildingPart = this.fields[2]?.value || '';
        this.material = this.fields[3]?.value || '';
        Object.keys(this.customFields).forEach(key => {
          this.customFieldsValues[key] = this.customFields[key].value;
        });
        Object.keys(this.fields).filter(x => x >= 11).forEach(x => {
          this.hierarchyFieldsValues[x] = this.fields[x].value;
        });
        this.originalBamData = result;
        // prevent instant element watcher
        setTimeout(() => {
          this.isInitiated = true;
        }, 1);
      },
    });
  },
  watch: {
    // issue: {
    //   handler(newVal) {
    //     console.log(newVal);
    //   },
    //   immediate: true,
    // },
    element() {
      if (!this.isInitiated) return;
      this.buildingPart = '';
      this.material = '';
    },
    buildingPart() {
      if (!this.isInitiated) return;
      this.material = '';
    },
    customFields() {
      const result = {};
      Object.keys(this.customFields).forEach(key => {
        result[key] = this.customFields[key].value || this.customFields[key].Value;
      });
      this.customFieldsValues = result;
    },
    // hierarchyFields(newVal) {
    //   const result = {};
    //   newVal.forEach(x => {
    //     result[x.id] = this.hierarchyFieldsValues[x.id] || this.fields[x.id]?.value;
    //   });
    //   this.hierarchyFieldsValues = result;
    // }
  },
}
