
import { mapState } from 'vuex';
import Loader from '../Loader';

export default {
  name: 'PhotoIssues',
  components: {
    Loader,
  },
  data: () => ({
    isAddFormActive: false,
  }),
  computed: {
    ...mapState('inspections__new', [
      'currentPhoto',

      'getInspectionIssuesResult',

      'getPhotoIssuesResult',
      'getPhotoIssuesIsLoading',
      'getPhotoIssuesError',
    ]),
    ...mapState('inspections__new/photos', [
      'highlightedIssue',
    ]),
    noPhotoIssues() {
      return this.getInspectionIssuesResult?.filter(x => !x.photo) || [];
    },
  },
  methods: {
    setIssue(issue) {
      this.highlightIssue(null);
      this.$store.commit('inspections__new/photos/toggleCurrentIssue', issue);
    },
    highlightIssue(issue) {
      this.$store.commit('inspections__new/photos/setHighlightedIssue', issue);
    },
    openAddForm() {
      this.$emit('open-add-form');
    },
    openAssignModal() {
      this.$store.dispatch('modals/show', {
        name: 'AssignIssuesToPhoto',
        props: {
          unassignedIssues: this.noPhotoIssues,
          photos: [ this.currentPhoto ],
          onComplete: () => {
            this.$store.dispatch('inspections__new/getInspectionIssues', { id: this.currentPhoto.inspection });
            this.$store.dispatch('inspections__new/getPhotoIssues', { id: this.currentPhoto.id });
          },
        },
      });
    },
  },
}
