
import { mapState } from 'vuex';
import PhotoZoom from './PhotoZoom';

export default {
  name: 'PhotoNotesAdd',
  components: {
    PhotoZoom,
  },
  data: () => ({
    positionX: 0,
    positionY: 0,
    urgency: 'Low',
    urgencyOptions: [
      { title: 'Low urgency', value: 'Low' },
      { title: 'Medium urgency', value: 'Medium' },
      { title: 'High urgency', value: 'High' },
    ],
    text: '',
    file: null,
  }),
  computed: {
    ...mapState('inspections__new', [
      'currentInspection',
      'currentPhoto',

      'getPhotoIssuesResult',
      'getPhotoIssuesIsLoading',
      'getPhotoIssuesError',
    ]),
    ...mapState('inspections__new/photos', [
      'currentIssue',
      'isDrawingActive',
      'isNotatingActive',
      'drawingPolygonPoints',
      'drawingPolygonCenter',
      'newNotationX',
      'newNotationY',
    ]),
    isLoading() {
      return  this.getPhotoIssuesIsLoading || this.addPhotoIssuesIsLoading || this.removePhotoIssuesIsLoading;
    },
  },
  methods: {
    addPositionHandler() {
      this.$store.state.inspections__new.photos.newNotationX = 0;
      this.$store.state.inspections__new.photos.newNotationY = 0;
      this.$store.state.inspections__new.photos.isNotatingActive = true;
    },
    addFileHandler(e) {
      if (e.target.files.length > 0) {
        this.file = e.target.files[0];
      } else {
        this.file = null;
      }
    },
    onSubmit() {
      if (!this.text) {
        return;
      }
      const payload = new FormData();
      payload.append('inspection', this.currentInspection.id);
      payload.append('photo', this.currentPhoto.id);
      payload.append('content', this.text);
      payload.append('urgency', this.urgency);
      if (this.isNotatingActive) {
        payload.append('position_photo', this.positionX / this.currentPhoto.image_width + ',' + this.positionY / this.currentPhoto.image_height);
      }
      if (this.file) {
        payload.append('file', this.file);
      }
      this.$store.dispatch('inspections__new/addPhotoNotes', payload);
    },
    backHandler() {
      this.$emit('close-add-form');
    },
  },
  beforeUnmount() {
    this.$store.state.inspections__new.photos.isNotatingActive = false;
  },
  watch: {
    newNotationX(newVal) {
      if (this.isNotatingActive) {
        this.positionX = newVal;
      }
    },
    newNotationY(newVal) {
      if (this.isNotatingActive) {
        this.positionY = newVal;
      }
    },
  },
}
