
import { mapState } from 'vuex';

export default {
  name: "PhotoToolbar",
  data: () => ({
    tools: [
      {
        title: 'Show info',
        code: 'info',
        icon: 'media/icons/duotune/general/gen045.svg',
      },
      {
        title: 'Zoom in',
        code: 'zoom',
        icon: 'media/icons/duotune/general/gen004.svg',
      },
      {
        title: 'Show notes',
        code: 'notes',
        icon: 'media/icons/duotune/general/gen054.svg',
      },
      {
        title: 'Show issues',
        code: 'issues',
        icon: 'media/icons/duotune/coding/cod009.svg',
      },
    ],
  }),
  computed: {
    ...mapState('inspections__new/photos', [
      'currentToolCode',
    ]),
    ...mapState('inspections__new', [
      'currentInspection',
    ]),
  },
  methods: {
    toolClickHandler(tool) {
      this.$store.state.inspections__new.photos.currentToolCode = tool.code;
    },
  },
  beforeMount() {
    this.$store.state.inspections__new.photos.currentToolCode = this.tools.length > 0 ? this.tools[0].code : null;
  },
}
