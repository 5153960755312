
import { mapState } from 'vuex';
import PhotoNotesListItem from './PhotoNotesListItem';

export default {
  name: 'PhotoNotesList',
  components: {
    PhotoNotesListItem,
  },
  computed: {
    ...mapState('inspections__new', [
      'currentPhoto',

      'getPhotoNotesResult',
      'getPhotoNotesIsLoading',
      'getPhotoNotesError',
    ]),
  },
  methods: {
    openAddForm() {
      this.$emit('open-add-form');
    }
  },
}
