
import { mapState } from 'vuex';

export default {
  name: 'PhotoNotesListItem',
  props: {
    note: Object,
  },
  data: () => ({
    isConfirmationNeeded: false,
  }),
  computed: {
    ...mapState('inspections__new', [
      'currentPhoto',

      'getPhotoNotesResult',
      'getPhotoNotesIsLoading',
      'getPhotoNotesError',
    ]),
    ...mapState('inspections__new/photos', [
      'highlightedNote',
    ]),
  },
  methods: {
    removeHandler() {
      this.isConfirmationNeeded = true;
    },
    remove() {
      this.$store.dispatch('inspections__new/removePhotoNotes', { id: this.note.id });
    },
    cancel() {
      this.isConfirmationNeeded = false;
    },
    highlightNote(note) {
      this.$store.commit('inspections__new/photos/setHighlightedNote', note);
    },
  },
}
