
import { mapState } from 'vuex'
import Loader from '../Loader'
import PhotoOverlay from './PhotoOverlay'

export default {
  name: 'PhotoImage',
  components: {
    Loader,
    PhotoOverlay,
  },
  data () {
    return {
      isLoaded: false,
      issues: [],
    }
  },
  computed: {
    ...mapState('inspections__new', [
      'currentPhoto',

      'getPhotoIssuesResult',
      'getPhotoIssuesIsLoading',
    ]),
    ...mapState('inspections__new/photos', [
      'currentToolCode',
      'currentIssue',
      'isDrawingActive',
    ]),
    isLoading() {
      return !this.isLoaded || this.getPhotoIssuesIsLoading;
    },
  },
  methods: {
    setLoaded() {
      this.isLoaded = true;
    },
    mouseenterHandler() {
      if (this.isDrawingActive) {
        this.$store.state.inspections__new.photos.isCursorNeeded = true;
      }
    },
    mouseleaveHandler() {
      this.$store.dispatch('inspections__new/photos/centerOnActivePoint');
      this.$store.state.inspections__new.photos.isCursorNeeded = false;
    },
  },
  watch: {
    currentPhoto() {
      this.isLoaded = false;
    }
  },
}
