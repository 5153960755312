
import { mapState } from 'vuex';

export default {
  name: 'PhotoOverlay',
  props: {
    isZoomed: {
      type: Boolean,
      defauld: false,
    },
  },
  computed: {
    ...mapState('inspections__new', [
      'currentPhoto',

      'getPhotoIssuesResult',

      'getPhotoNotesResult',
    ]),
    ...mapState('inspections__new/photos', [
      'currentToolCode',
      'currentIssue',
      'highlightedIssue',
      'isDrawingActive',
      'isNotatingActive',
      'highlightedNote',
      'drawingPolygonPoints',
      'cursorOffsetX',
      'cursorOffsetY',
    ]),
    rootEventHandlers() {
      if (this.isDrawingActive) {
        return { contextmenu: this.stopDrawing, click: this.addPoint };
      }
      if (this.isNotatingActive) {
        return { click: this.setNotationPosition };
      }
      return {};
    },
    isUpdateNeeded() {
      if (this.isZoomed) {
        return false;
      }
      return this.isDrawingActive ||
        this.drawingPolygonPoints.length > 0 ||
        this.currentToolCode === 'zoom' ||
        (this.currentToolCode === 'issues' && this.currentIssue) ||
        this.isNotatingActive;
    },
    issuesWithVertices() {
      if (!this.getPhotoIssuesResult) {
        return null;
      }
      return this.getPhotoIssuesResult.filter(x => !!x.vertices && Array.isArray(x.vertices)).map(x => ({ ...x, vertices: x.vertices.map(x => x.join(',')).join(' ') }));
    },
    notesWithPosition() {
      if (!this.getPhotoNotesResult) {
        return null;
      }
      return this.getPhotoNotesResult
        .filter(x => !!x.position_photo)
        .map(x => ({
          ...x,
          position_photo: x.position_photo
            .split(',')
            .map((x, i) => Math.round(parseFloat(x) * this.currentPhoto[ i === 0 ? 'image_width' : 'image_height' ])),
        }));
    },
    issueHint() {
      if (this.isZoomed || (!this.highlightedIssue?.vertices_center && !this.currentIssue?.vertices_center)) {
        return null;
      }
      const issue = this.highlightedIssue?.vertices_center ? this.highlightedIssue : this.currentIssue;
      //  * |
      //____|____
      //    |
      //    |
      const style = { transform: 'translate(2ch, 1.45em)' };

      //    | *
      //____|____
      //    |
      //    |
      if (issue.vertices_center[0] >= this.currentPhoto.image_width / 2 &&
        issue.vertices_center[1] >= this.currentPhoto.image_height / 2) {
        style.transform = `translate(-${issue.issue_type.length + 4}ch, 1.45em)`;

      //    |
      //____|____
      //    |
      //    | *
      } else if (issue.vertices_center[0] >= this.currentPhoto.image_width / 2 &&
        issue.vertices_center[1] < this.currentPhoto.image_height / 2) {
        style.transform = `translate(-${issue.issue_type.length + 4}ch, -1.45em)`;

      //    |
      //____|____
      //    |
      //  * |
      } else if (issue.vertices_center[0] < this.currentPhoto.image_width / 2 &&
        issue.vertices_center[1] < this.currentPhoto.image_height / 2) {
        style.transform = 'translate(2ch, -1.45em)';
      }
      const hint = {
        x: issue.vertices_center[0],
        y: issue.vertices_center[1],
        text: issue.issue_type,
        width: issue.issue_type.length + 2 + 'ch',
        styleRect: 'transform: translate(-1ch, -1.45em)',
        style,
      };
      return hint;
    },
  },
  methods: {
    mouseMoveHandler(e) {
      if (this.isUpdateNeeded) {
        this.$store.state.inspections__new.photos.cursorOffsetX = e.offsetX / e.target.width.baseVal.value;
        this.$store.state.inspections__new.photos.cursorOffsetY = e.offsetY / e.target.height.baseVal.value;
      }
    },
    mouseMoveChildHandler(e) {
      if (this.isUpdateNeeded) {
        this.$store.state.inspections__new.photos.cursorOffsetX = e.offsetX / e.target.parentElement.width.baseVal.value;
        this.$store.state.inspections__new.photos.cursorOffsetY = e.offsetY / e.target.parentElement.height.baseVal.value;
      }
    },
    clickPolygonHandler(issue) {
      if (this.isDrawingActive || this.isNotatingActive) {
        return;
      }
      this.$store.commit('inspections__new/photos/toggleCurrentIssue', issue);
    },
    addPoint(e) {
      if (this.isZoomed) {
        this.$store.dispatch('inspections__new/photos/addPolygonPoint', [
          e.offsetX,
          e.offsetY,
        ]);
      } else {
        this.$store.dispatch('inspections__new/photos/addPolygonPoint', [
          Math.floor(this.cursorOffsetX * this.currentPhoto.image_width),
          Math.floor(this.cursorOffsetY * this.currentPhoto.image_height),
        ]);
      }
    },
    setNotationPosition() {
      this.$store.state.inspections__new.photos.newNotationX = Math.floor(this.cursorOffsetX * this.currentPhoto.image_width);
      this.$store.state.inspections__new.photos.newNotationY = Math.floor(this.cursorOffsetY * this.currentPhoto.image_height);
    },
    highlightIssue(issue) {
      this.$store.commit('inspections__new/photos/setHighlightedIssue', issue);
    },
    highlightNote(note) {
      this.$store.commit('inspections__new/photos/setHighlightedNote', note);
    },
    clickCircleHandler() {
      if (this.isDrawingActive || this.isNotatingActive) {
        return;
      }
      this.$store.state.inspections__new.photos.currentToolCode = 'notes';
    },
    stopDrawing(e) {
      e.preventDefault();
      this.$store.state.inspections__new.photos.isDrawingActive = false;
    },
  },
}
