
import { mapState } from 'vuex';
import Loader from '../Loader';
import PhotoNotesAdd from './PhotoNotesAdd';
import PhotoNotesList from './PhotoNotesList';

export default {
  name: 'PhotoNotes',
  components: {
    Loader,
    PhotoNotesAdd,
    PhotoNotesList,
  },
  data: () => ({
    isAddFormActive: false,
  }),
  computed: {
    ...mapState('inspections__new', [
      'currentPhoto',

      'getPhotoNotesResult',
      'getPhotoNotesIsLoading',
      'getPhotoNotesError',

      'addPhotoNotesResult',
      'addPhotoNotesIsLoading',
      'addPhotoNotesError',

      'removePhotoNotesResult',
      'removePhotoNotesIsLoading',
      'removePhotoNotesError',
    ]),
    ...mapState('inspections__new/photos', [
    ]),
    isLoading() {
      return  this.getPhotoNotesIsLoading || this.addPhotoNotesIsLoading || this.removePhotoNotesIsLoading;
    },
  },
  methods: {
    openAddForm() {
      this.isAddFormActive = true;
    },
    closeAddForm() {
      this.isAddFormActive = false;
    },
  },
  watch: {
    addPhotoNotesIsLoading(newVal) {
      if (!newVal && this.addPhotoNotesResult) {
        this.closeAddForm();
        this.$store.dispatch('inspections__new/getPhotoNotes', { id: this.currentPhoto.id });
      }
    },
    removePhotoNotesIsLoading(newVal) {
      if (!newVal && this.removePhotoNotesResult) {
        this.$store.dispatch('inspections__new/getPhotoNotes', { id: this.currentPhoto.id });
      }
    },
  },
}
